import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../wrapper.styles';
import { FormGroupComponent } from '@components/input/FormGroup/FormGroup';
import { PhoneInput } from '@/components/input/PhoneInput/PhoneInput';
import { TextInput } from '../../components/input/TextInput/TextInput';
import { TextBlock } from '@components/text/TextBlock';
import { TypeOfText } from '@components/text/TextBlock';
import { DropDownInput } from '@/components/input/DropDownInput/DropDownInput';
import DataPicker from '@/components/input/DatePicker/DatePicker';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { CheckConsent } from '@/components/input/CheckConsent/CheckConsent';
import { NavigationButton, PageOrigin } from '@/components/Button/NavigationButton/NavigationButton';
import { useIsMobile } from '@/hooks/useIsMobile';
import { PersonalInfoWrapper, ButtonWrapper, ConfirmMessageContainer } from './PersonalInformation.styles';
import { Button } from '@/components/Button/DefaultButton/Button';
import _ from 'lodash';
import { fieldFilled } from '@/utils/handleFieldsFilled';
import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { pages } from '@/defaultVerbiages';

interface PersonalInformationProps {
  onAccess: (page: string) => void;
}

type Borrower = {
  selectStatus: any;
  dateBirthday: string | undefined;
  SSN: any;
  contactMethod: any;
  citizenship: any;
  mobile?: string;
  homePhone?: string;
  workPhone?: string;
};

type CoBorrower = {
  firstName: string;
  lastName: string;
  relationship: string;
  selectStatusCoborrower: any;
  dateBirthdayCoBorrower: string | undefined;
  mobilePhoneCoBorrower?: string;
  email: string;
  citizenshipCoborrower: any;
  contactMethodCoBorrower: any;
  SSNCoBorrower: any;
  workPhoneCoBorrower?: string;
  homePhoneCoBorrower?: string;
};

export enum PropertyType {
  Status = 'selectStatus',
  DateBirthday = 'dateBirthday',
  SSN = 'SSN',
  MobilePhone = 'mobile',
  HomePhone = 'homePhone',
  WorkPhone = 'workPhone',
  ContactMethod = 'contactMethod',
  Citizenship = 'citizenship',
  MailAddress = 'emailAddress',
  FirstNameCoBorrower = 'firstNameCoBorrower',
  MiddleName = 'middleName',
  LastNameCoborrower = 'lastNameCoborrower',
  Suffix = 'suffix',
  Relationship = 'relationship',
  StatusCoBorrower = 'selectStatusCoborrower',
  DateBirthdayCoBorrower = 'dateBirthdayCoBorrower',
  MobilePhoneCoBorrower = 'mobilePhoneCoBorrower',
  HomePhoneCoBorrower = 'homePhoneCoBorrower',
  WorkPhoneCoBorrower = 'workPhoneCoBorrower',
  EmailCoborrower = 'emailCoborrower',
  CitizenshipCoborrower = 'citizenshipCoborrower',
  ContactMethodCoBorrower = 'contactMethodCoBorrower',
  SSNCoBorrower = 'SSNCoBorrower',
}
const PersonalInformation: React.FC<PersonalInformationProps> = ({ onAccess }) => {
  const [coBorrower, setCoBorrower] = useState<number>(0);
  const [, setBorrowerInfo] = useState({ date: '', email: '' });
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const {
    selectStatus,
    SSN,
    mobile,
    workPhone,
    homePhone,
    contactMethod,
    citizenship,
    emailAddress,
    firstNameCoBorrower,
    middleNameCoBorrower,
    lastNameCoborrower,
    suffix,
    relationship,
    selectStatusCoborrower,
    dateBirthdayCoBorrower,
    mobilePhoneCoBorrower,
    homePhoneCoBorrower,
    workPhoneCoBorrower,
    citizenshipCoborrower,
    emailCoborrower,
    contactMethodCoBorrower,
    SSNCoBorrower,
    TCPA,
  } = useAppSelector((state) => state.CurrentForm);
  const hasCoBorrower = coBorrower !== 0;
  const hasDrawAmountPage = templateConfig?.pages?.hasDrawAmountPage ?? false;
  const pageNavigation = hasDrawAmountPage ? PageOrigin.VMO_DRAW_AMOUNT : PageOrigin.VMO_LINE_AMOUNT;
  const previousPage = templateConfig?.features?.vmoRedesign?.isEnabled ? PageOrigin.VMO : pageNavigation;
  const isLoanDepot = templateConfig?.lenderName === 'loanDepot';
  const hasDateBirthday = loanApplication?.borrowers[0]?.borrowerDOB !== '';
  const TCPARequired = templateConfig?.pages?.personalInformation?.checkConsent?.isRequired || false;

  const [formValues, setFormValues] = useState({
    selectStatus: selectStatus || '',
    dateBirthday: loanApplication?.borrowers[0]?.borrowerDOB,
    SSN: SSN || '',
    mobile: mobile || '',
    homePhone: homePhone || '',
    workPhone: workPhone || '',
    contactMethod: contactMethod || '',
    citizenship: citizenship || '',
    emailAddress: emailAddress || '',
    firstNameCoBorrower: firstNameCoBorrower || '',
    middleNameCoBorrower: middleNameCoBorrower || '',
    lastNameCoborrower: lastNameCoborrower || '',
    suffixCoborrower: suffix || '',
    relationship: relationship || '',
    selectStatusCoborrower: selectStatusCoborrower || '',
    dateBirthdayCoBorrower: dateBirthdayCoBorrower || '',
    mobilePhoneCoBorrower: mobilePhoneCoBorrower || '',
    homePhoneCoBorrower: homePhoneCoBorrower || '',
    workPhoneCoBorrower: workPhoneCoBorrower || '',
    citizenshipCoborrower: citizenshipCoborrower || '',
    emailCoborrower: emailCoborrower || '',
    contactMethodCoBorrower: contactMethodCoBorrower || '',
    SSNCoBorrower: SSNCoBorrower || '',
    TCPA: TCPA || false,
  });

  const [formError, setFormError] = useState(false);
  const handleChange = _.debounce((value: string | number | boolean, name: string) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    let obj: any = {};
    obj[name] = value;
    dispatch({ type: 'CurrentForm/addField', payload: { ...formValues, [name]: value } });
  }, 200);

  const requiredValues = {
    borrower: {
      selectStatus: formValues.selectStatus,
      dateBirthday: hasDateBirthday ? loanApplication?.borrowers[0]?.borrowerDOB : formValues.dateBirthday,
      SSN: formValues.SSN,
      contactMethod: formValues.contactMethod,
      citizenship: formValues.citizenship,
      ...(TCPARequired && { TCPA: formValues.TCPA }),
    } as Borrower,
    coBorrower: {
      firstName: formValues.firstNameCoBorrower,
      lastName: formValues.lastNameCoborrower,
      relationship: formValues.relationship,
      selectStatusCoborrower: formValues.selectStatusCoborrower,
      dateBirthdayCoBorrower: formValues.dateBirthdayCoBorrower,
      email: formValues.emailCoborrower,
      citizenshipCoborrower: formValues.citizenshipCoborrower,
      contactMethodCoBorrower: formValues.contactMethodCoBorrower,
      SSNCoBorrower: formValues.SSNCoBorrower,
    } as CoBorrower,
  };
  const coApplicantLabel = templateConfig.pages?.personalInformation?.formInputs?.Coborrower?.coApplicantLabel || 'Co-Borrower';
  const coBorrowerPhoneRequired = templateConfig?.pages?.personalInformation?.formInputs?.Coborrower;

  if (coBorrowerPhoneRequired?.mobilePhone?.isRequired || formValues.contactMethodCoBorrower === 'cell') {
    requiredValues.coBorrower.mobilePhoneCoBorrower = formValues.mobilePhoneCoBorrower;
  }
  if (coBorrowerPhoneRequired?.homePhone?.isRequired || formValues.contactMethodCoBorrower === 'home') {
    requiredValues.coBorrower.homePhoneCoBorrower = formValues.homePhoneCoBorrower;
  }
  if (coBorrowerPhoneRequired?.workPhone?.isRequired || formValues.contactMethodCoBorrower === 'work') {
    requiredValues.coBorrower.workPhoneCoBorrower = formValues.workPhoneCoBorrower;
  }
  const borrowerPhoneRequired = templateConfig?.pages?.personalInformation?.formInputs;
  if (borrowerPhoneRequired?.mobilePhone?.isRequired || formValues.contactMethod === 'cell') {
    requiredValues.borrower.mobile = formValues.mobile;
  }
  if (borrowerPhoneRequired?.homePhone?.isRequired || formValues.contactMethod === 'home') {
    requiredValues.borrower.homePhone = formValues.homePhone;
  }
  if (borrowerPhoneRequired?.workPhone?.isRequired || formValues.contactMethod === 'work') {
    requiredValues.borrower.workPhone = formValues.workPhone;
  }

  const allRequiredValues = hasCoBorrower ? { ...requiredValues.borrower, ...requiredValues.coBorrower } : requiredValues.borrower;
  useEffect(() => {
    const allFieldsFilled = fieldFilled(allRequiredValues);
    setFormError(allFieldsFilled);
  }, [formValues, hasCoBorrower]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loanApplication?.borrowers[1]) {
      setCoBorrower(1);
    }
    if (loanApplication?.borrowers[0]?.borrowerDOB) {
      setBorrowerInfo({ date: loanApplication?.borrowers[0]?.borrowerDOB, email: loanApplication?.borrowers[0]?.borrowerEmailAddress });
    }
  }, []);

  const [employmentHistoryCoBorrower, setEmploymentHistoryCoBorrower] = useState<
    {
      appendValue: number;
      type: string;
      employerName: string;
      jobTitle: string;
      startDate: string;
      endDate: string;
      grossMonthlyIncome: number;
      currentEmployment: boolean | null;
      totalMonths?: undefined | number;
    }[]
  >([
    {
      appendValue: 0,
      type: '',
      employerName: '',
      jobTitle: '',
      startDate: '',
      endDate: '',
      grossMonthlyIncome: 0,
      currentEmployment: null,
      totalMonths: 0,
    },
  ]);

  useEffect(() => {
    if (loanApplication?.borrowers[1]?.employmentHistory) {
      setEmploymentHistoryCoBorrower(loanApplication?.borrowers[1]?.employmentHistory);
    }
  }, [loanApplication?.borrowers[1]]);

  useEffect(() => {
    let auxLoanApplication: LoanApplicationState = _.cloneDeep(loanApplication);

    const updatedBorrower = {
      ...auxLoanApplication.borrowers[0],
      borrowerPrimaryPhone: formValues.mobile,
      borrowerAlternatePhone: formValues.homePhone,
      borrowerWorkPhone: formValues.workPhone,
      borrowerCitizenship: formValues.citizenship,
      borrowerMaritalStatus: formValues.selectStatus,
      borrowerPreferredContactMethod: formValues.contactMethod,
      borrowerSSN: formValues.SSN,
      borrowerDOB: formValues.dateBirthday,
    };
    if (hasCoBorrower) {
      const updatedCoBorrower = {
        ...auxLoanApplication.borrowers[1],
        borrowerFirstName: formValues.firstNameCoBorrower,
        borrowerMiddleName: formValues.middleNameCoBorrower,
        borrowerLastName: formValues.lastNameCoborrower,
        borrowerRelationship: formValues.relationship,
        borrowerMaritalStatus: formValues.selectStatusCoborrower,
        borrowerDOB: formValues.dateBirthdayCoBorrower,
        borrowerEmailAddress: formValues.emailCoborrower,
        borrowerPrimaryPhone: formValues.mobilePhoneCoBorrower,
        borrowerAlternatePhone: formValues.homePhoneCoBorrower,
        borrowerCitizenship: formValues.citizenshipCoborrower,
        borrowerWorkPhone: formValues.workPhoneCoBorrower,
        borrowerPreferredContactMethod: formValues.contactMethodCoBorrower,
        borrowerSSN: formValues.SSNCoBorrower,
        appendValue: 1,
        isPrimary: false,
        employmentHistory: employmentHistoryCoBorrower,
        governmentHMDA: {
          applicantRace: [],
          applicantEthnicity: [],
          applicantSex: [],
        },
        otherIncome: [],
      };
      auxLoanApplication.borrowers[1] = updatedCoBorrower;
    } else if (coBorrower === 0 && auxLoanApplication.borrowers?.[1]) {
      auxLoanApplication?.borrowers?.splice(1, 1);
    }
    auxLoanApplication.isTcpaAccepted = formValues.TCPA;
    auxLoanApplication.isTcpaAccepted = formValues.TCPA;
    auxLoanApplication.borrowers[0] = updatedBorrower;

    dispatch({
      type: 'LoanApplication/updateValue',
      payload: { ...auxLoanApplication },
    });
  }, [formValues, coBorrower]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleIsRequired = (value: 'cell' | 'home' | 'work' | 'Email', type: 'borrower' | 'coBorrower'): boolean => {
    const contactMethod = type === 'borrower' ? formValues?.contactMethod : formValues?.contactMethodCoBorrower;
    return (value === 'cell' && isLoanDepot) || value.toLowerCase() === contactMethod.toLowerCase();
  };

  const { personalInformation } = pages;
  const templatePersonalInformation = templateConfig?.pages?.personalInformation;

  return (
    <>
      <PersonalInfoWrapper $isMobile={isMobile}>
        <Wrapper $gap={isMobile ? '0rem' : '1.563rem'} $isMobile={isMobile}>
          <TextBlock
            text={`${templatePersonalInformation?.placeHolder?.text || personalInformation.placeHolder.text} ${loanApplication?.borrowers?.[0].borrowerFirstName || ''} ${loanApplication?.borrowers?.[0].borrowerLastName || ''}`}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H2}
            isHidden={templatePersonalInformation?.placeHolder?.isHidden || false}
          />
          <FormGroupComponent data-testid="address-form" gap="1.563rem" onChange={handleChange}>
            <DropDownInput
              label={templatePersonalInformation?.formInputs?.selectStatus?.label || personalInformation.formInputs.selectStatus.label}
              placeHolder={templatePersonalInformation?.formInputs?.selectStatus?.placeHolder || personalInformation.formInputs.selectStatus.placeHolder}
              options={templatePersonalInformation?.formInputs?.selectStatus?.options}
              size="23%"
              typeOfIcon="dollar"
              labelContainer={true}
              hasAsterisk={templatePersonalInformation?.formInputs?.selectStatus?.isRequired || false}
              propertyType={PropertyType.Status}
              isHidden={templatePersonalInformation?.formInputs?.selectStatus?.isHidden}
              isInfoModal={true}
              infoModal={templatePersonalInformation?.modals?.maritalStatusInfo}
            />
            <DataPicker
              label={templatePersonalInformation?.formInputs?.dateBirth?.label || personalInformation.formInputs.dateBirth.label}
              labelContainer={true}
              placeholder={templatePersonalInformation?.formInputs?.dateBirth?.placeHolder || personalInformation.formInputs.dateBirth.placeHolder}
              typeOfIcon="date"
              propertyName={'dateOfBirth'}
              propertyType={PropertyType.DateBirthday}
              hasAsterisk={templatePersonalInformation?.formInputs?.dateBirth?.isRequired || false}
              value={loanApplication?.borrowers[0]?.borrowerDOB || ''}
              isHidden={templatePersonalInformation?.formInputs?.dateBirth?.isHidden ?? false}
            />
            <TextInput
              label={templatePersonalInformation?.formInputs?.SSN?.label || personalInformation.formInputs.SSN.label}
              placeholder={templatePersonalInformation?.formInputs?.SSN?.placeHolder || personalInformation.formInputs.SSN.placeHolder}
              size="25%"
              typeOfIcon="text"
              isInfoModal={false}
              hasAsterisk={true}
              propertyType={PropertyType.SSN}
              isHidden={templatePersonalInformation?.formInputs?.SSN?.isHidden ?? false}
              type="password"
            />
            <ConfirmMessageContainer $isHidden={templatePersonalInformation?.messages?.ssnMessages?.isHidden ?? false}>
              <TextBlock
                text={templatePersonalInformation?.messages?.ssnMessages?.title}
                type={TypeOfText.H1}
                color="primary"
                isHidden={templatePersonalInformation?.messages?.ssnMessages?.isHidden}
              />
              <TextBlock
                text={templatePersonalInformation?.messages?.ssnMessages?.body}
                type={TypeOfText.H1}
                isHidden={templatePersonalInformation?.messages?.ssnMessages?.isHidden}
              />
            </ConfirmMessageContainer>
            <PhoneInput
              placeHolder={templatePersonalInformation?.formInputs?.mobilePhone?.placeHolder || personalInformation.formInputs.mobilePhone.placeHolder}
              labelContainer={true}
              label={templatePersonalInformation?.formInputs?.mobilePhone?.label || personalInformation.formInputs.mobilePhone.label}
              size={'30%'}
              type="borrowerAlternatePhone"
              value={loanApplication?.borrowers[0]?.borrowerPrimaryPhone || ''}
              options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
              propertyName={'personal-information'}
              hasAsterisk={templatePersonalInformation?.formInputs?.mobilePhone?.isRequired ? true : handleIsRequired('cell', 'borrower')}
              isRequired={templatePersonalInformation?.formInputs?.mobilePhone?.isRequired ? true : handleIsRequired('cell', 'borrower')}
              propertyType={PropertyType.MobilePhone}
              isHidden={templatePersonalInformation?.formInputs?.mobilePhone?.isHidden ?? false}
            />
            <PhoneInput
              placeHolder={templatePersonalInformation?.formInputs?.homePhone?.placeHolder || personalInformation.formInputs.homePhone.placeHolder}
              labelContainer={true}
              label={templatePersonalInformation?.formInputs?.homePhone?.label || personalInformation.formInputs.homePhone.label}
              size={'30%'}
              type="borrowerPrimaryPhone"
              value={loanApplication?.borrowers[0]?.borrowerAlternatePhone || ''}
              options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
              isHidden={templatePersonalInformation?.formInputs?.homePhone?.isHidden ?? false}
              propertyName={'personal-information'}
              hasAsterisk={templatePersonalInformation?.formInputs?.homePhone?.isRequired ? true : handleIsRequired('home', 'borrower')}
              isRequired={templatePersonalInformation?.formInputs?.mobilePhone?.isRequired ? true : handleIsRequired('home', 'borrower')}
              propertyType={PropertyType.HomePhone}
            />
            <PhoneInput
              placeHolder={templatePersonalInformation?.formInputs?.workPhone?.placeHolder || personalInformation.formInputs.workPhone.placeHolder}
              labelContainer={true}
              label={templatePersonalInformation?.formInputs?.workPhone?.label || personalInformation.formInputs.workPhone.label}
              size={'30%'}
              type="borrowerWorkPhone"
              value={loanApplication?.borrowers[0]?.borrowerWorkPhone || ''}
              options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
              isHidden={templatePersonalInformation?.formInputs?.workPhone?.isHidden ?? false}
              propertyName={'personal-information'}
              isRequired={templatePersonalInformation?.formInputs?.mobilePhone?.isRequired ? true : handleIsRequired('work', 'borrower')}
              hasAsterisk={templatePersonalInformation?.formInputs?.workPhone?.isRequired ? true : handleIsRequired('work', 'borrower')}
              propertyType={PropertyType.WorkPhone}
            />
            <DropDownInput
              label={templatePersonalInformation?.formInputs?.contactMethod?.label || personalInformation.formInputs.contactMethod.label}
              options={templatePersonalInformation?.formInputs?.contactMethod?.options}
              size="28%"
              placeHolder={templatePersonalInformation?.formInputs?.contactMethod?.placeHolder || personalInformation.formInputs.contactMethod.placeHolder}
              labelContainer={true}
              hasAsterisk={templatePersonalInformation?.formInputs?.contactMethod?.isRequired}
              propertyType={PropertyType.ContactMethod}
              isHidden={templatePersonalInformation?.formInputs?.contactMethod?.isHidden ?? false}
            />
            <DropDownInput
              label={templatePersonalInformation?.formInputs?.citizenship?.label || personalInformation.formInputs.citizenship.label}
              options={templatePersonalInformation?.formInputs?.citizenship?.options}
              size="28%"
              placeHolder={templatePersonalInformation?.formInputs?.citizenship?.placeHolder || personalInformation.formInputs.citizenship.placeHolder}
              labelContainer={true}
              typeOfIcon="location"
              hasAsterisk={templatePersonalInformation?.formInputs?.citizenship?.isRequired || false}
              capitalizeWord={false}
              propertyType={PropertyType.Citizenship}
              isHidden={templatePersonalInformation?.formInputs?.citizenship?.isHidden ?? false}
            />
            <TextInput
              label={templatePersonalInformation?.formInputs?.emailAddress?.label || personalInformation.formInputs.emailAddress.label}
              placeholder={templatePersonalInformation?.formInputs?.emailAddress?.placeHolder || personalInformation.formInputs.emailAddress.placeHolder}
              size={'32%'}
              typeOfIcon="email"
              type="email"
              value={loanApplication?.borrowers[0]?.borrowerEmailAddress || ''}
              hasAsterisk={templatePersonalInformation?.formInputs?.emailAddress?.isRequired || false}
              propertyType={PropertyType.MailAddress}
              isHidden={templatePersonalInformation?.formInputs?.emailAddress?.isHidden || false}
            />
          </FormGroupComponent>
          {hasCoBorrower ? (
            <TextBlock
              text={templatePersonalInformation?.formInputs?.Coborrower?.title?.text?.replace('%%coApplicantLabel%%', coApplicantLabel) || 'Co-Borrower'}
              isHidden={templatePersonalInformation?.formInputs?.Coborrower?.title?.isHidden ?? false}
              direction={'flex-start'}
              color="default"
              type={TypeOfText.H2}
            />
          ) : (
            <></>
          )}
          {coBorrower < 1 ? (
            <ButtonWrapper $isHidden={templatePersonalInformation?.button?.add?.isHidden ?? false}>
              <Button
                label={templatePersonalInformation?.button?.add?.text?.replace('%%coApplicantLabel%%', coApplicantLabel) || personalInformation.button.add.text}
                hasIcon={true}
                onClick={() => setCoBorrower(coBorrower + 1)}
                data-testid="button"
                variant="secondary"
              />
            </ButtonWrapper>
          ) : (
            <ButtonWrapper $isHidden={templatePersonalInformation?.button?.remove?.isHidden ?? false}>
              <Button
                label={templatePersonalInformation?.button?.remove?.text.replace('%%coApplicantLabel%%', coApplicantLabel) || personalInformation.button.remove.text}
                onClick={() => setCoBorrower(coBorrower - 1)}
                data-testid="button"
                variant="secondary"
              />
            </ButtonWrapper>
          )}
          {coBorrower !== 0 &&
            Array.from({ length: coBorrower }).map((_, index) => (
              <FormGroupComponent key={index} onChange={handleChange}>
                <ConfirmMessageContainer $isHidden={templatePersonalInformation?.messages?.coBorrowerMessages?.isHidden ?? false}>
                  <TextBlock
                    text={templatePersonalInformation?.messages?.coBorrowerMessages?.title?.replace('%%coApplicantLabel%%', coApplicantLabel)}
                    type={TypeOfText.H1}
                    color="primary"
                    isHidden={templatePersonalInformation?.messages?.coBorrowerMessages?.isHidden}
                  />
                  <TextBlock
                    text={templatePersonalInformation?.messages?.coBorrowerMessages?.body?.replace('%%coApplicantLabel%%', coApplicantLabel)}
                    type={TypeOfText.H1}
                    isHidden={templatePersonalInformation?.messages?.coBorrowerMessages?.isHidden}
                  />
                </ConfirmMessageContainer>
                <TextInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.firstName?.label || personalInformation.formInputs.Coborrower.firstName.label}
                  placeholder={templatePersonalInformation?.formInputs?.Coborrower?.firstName?.placeHolder || personalInformation.formInputs.Coborrower.firstName.placeHolder}
                  typeOfIcon="text"
                  size={'30%'}
                  propertyType={PropertyType.FirstNameCoBorrower}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.firstName?.isHidden ?? false}
                  isRequired={true}
                  hasAsterisk={true}
                />
                <TextInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.middleName?.label || personalInformation.formInputs.Coborrower.middleName.label}
                  placeholder={templatePersonalInformation?.formInputs?.Coborrower?.middleName?.placeHolder || personalInformation.formInputs.Coborrower.middleName.placeHolder}
                  typeOfIcon="text"
                  size={'20%'}
                  isRequired={false}
                  propertyType={PropertyType.MiddleName}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.middleName?.isHidden ?? false}
                />
                <TextInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.lastName?.label || personalInformation.formInputs.Coborrower.lastName.label}
                  placeholder={templatePersonalInformation?.formInputs?.Coborrower?.lastName?.placeHolder || personalInformation.formInputs.Coborrower.lastName.placeHolder}
                  typeOfIcon="text"
                  size={'30%'}
                  propertyType={PropertyType.LastNameCoborrower}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.lastName?.isHidden ?? false}
                  isRequired={true}
                  hasAsterisk={true}
                />
                <DropDownInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.suffix?.label || personalInformation.formInputs.Coborrower.suffix.label}
                  placeHolder={templatePersonalInformation?.formInputs?.Coborrower?.suffix?.title || personalInformation.formInputs.Coborrower.suffix.title}
                  options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suffix?.options}
                  isRequired={false}
                  labelContainer={true}
                  size={'10%'}
                  propertyType={PropertyType.Suffix}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.suffix?.isHidden || false}
                  capitalizeWord={false}
                />
                <DropDownInput
                  labelContainer={true}
                  label={templatePersonalInformation?.formInputs?.Coborrower?.relationship?.label || personalInformation.formInputs.Coborrower.relationship.label}
                  placeHolder={templatePersonalInformation?.formInputs?.Coborrower?.relationship?.placeHolder || personalInformation.formInputs.Coborrower.relationship.placeHolder}
                  options={templatePersonalInformation?.formInputs?.Coborrower?.relationship?.options}
                  isRequired={false}
                  size={'23%'}
                  propertyType={PropertyType.Relationship}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.relationship?.isHidden ?? false}
                  hasAsterisk={true}
                />
                <DropDownInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.selectStatus?.label || personalInformation.formInputs.Coborrower.selectStatus.label}
                  placeHolder={templatePersonalInformation?.formInputs?.Coborrower?.selectStatus?.placeHolder || personalInformation.formInputs.Coborrower.selectStatus.placeHolder}
                  options={templatePersonalInformation?.formInputs?.Coborrower?.selectStatus?.options}
                  size="25%"
                  typeOfIcon="dollar"
                  labelContainer={true}
                  propertyType={PropertyType.StatusCoBorrower}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.selectStatus?.isHidden ?? false}
                  isRequired={true}
                  hasAsterisk={true}
                  isInfoModal={true}
                  infoModal={templatePersonalInformation?.modals?.maritalStatusInfo}
                />
                <DataPicker
                  label={templatePersonalInformation?.formInputs?.Coborrower?.dateBirth?.label || personalInformation.formInputs.Coborrower.dateBirth.label}
                  labelContainer={true}
                  placeholder={templatePersonalInformation?.formInputs?.Coborrower?.dateBirth?.placeHolder || personalInformation.formInputs.Coborrower.dateBirth.placeHolder}
                  typeOfIcon="date"
                  propertyName={'dateOfBirth'}
                  propertyType={PropertyType.DateBirthdayCoBorrower}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.dateBirth?.isHidden ?? false}
                  hasAsterisk={true}
                />
                <PhoneInput
                  placeHolder={templatePersonalInformation?.formInputs?.Coborrower?.mobilePhone?.placeHolder || personalInformation.formInputs.Coborrower.mobilePhone.placeHolder}
                  labelContainer={true}
                  label={templatePersonalInformation?.formInputs?.Coborrower?.mobilePhone?.label || personalInformation.formInputs.Coborrower.mobilePhone.label}
                  size={'24%'}
                  type="borrowerAlternatePhone"
                  options={
                    templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options as [
                      {
                        label: string;
                        beValue: string;
                      },
                    ]
                  }
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.mobilePhone?.isHidden ?? false}
                  propertyName={'personal-information'}
                  hasAsterisk={templatePersonalInformation?.formInputs?.mobilePhone?.isRequired ? true : handleIsRequired('cell', 'coBorrower')}
                  isRequired={templatePersonalInformation?.formInputs?.mobilePhone?.isRequired ? true : handleIsRequired('cell', 'coBorrower')}
                  propertyType={PropertyType.MobilePhoneCoBorrower}
                />
                <PhoneInput
                  placeHolder={templatePersonalInformation?.formInputs?.Coborrower?.homePhone?.placeHolder || personalInformation.formInputs.Coborrower.homePhone.placeHolder}
                  labelContainer={true}
                  label={templatePersonalInformation?.formInputs?.Coborrower?.homePhone?.label || personalInformation.formInputs.Coborrower.homePhone.label}
                  size={'28%'}
                  type="borrowerPrimaryPhone"
                  options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.homePhone?.isHidden ?? false}
                  propertyName={'personal-information'}
                  hasAsterisk={handleIsRequired('home', 'coBorrower')}
                  propertyType={PropertyType.HomePhoneCoBorrower}
                />
                <PhoneInput
                  placeHolder={templatePersonalInformation?.formInputs?.Coborrower?.workPhone?.placeHolder || personalInformation.formInputs.Coborrower.workPhone.placeHolder}
                  labelContainer={true}
                  label={templatePersonalInformation?.formInputs?.Coborrower?.workPhone?.label || personalInformation.formInputs.Coborrower.workPhone.label}
                  size={'30%'}
                  type="borrowerWorkPhone"
                  options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.workPhone?.isHidden ?? false}
                  propertyName={'personal-information'}
                  hasAsterisk={handleIsRequired('work', 'coBorrower')}
                  propertyType={PropertyType.WorkPhoneCoBorrower}
                />
                <TextInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.emailAddress?.label || personalInformation.formInputs.Coborrower.emailAddress.label}
                  placeholder={templatePersonalInformation?.formInputs?.Coborrower?.emailAddress?.placeHolder || personalInformation.formInputs.Coborrower.emailAddress.placeHolder}
                  size={'32%'}
                  typeOfIcon="email"
                  type="email"
                  hasAsterisk={true}
                  propertyType={PropertyType.EmailCoborrower}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.emailAddress?.isHidden ?? false}
                  isRequired={true}
                />
                <DropDownInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.contactMethod?.label || personalInformation.formInputs.Coborrower.contactMethod.label}
                  options={templatePersonalInformation?.formInputs?.Coborrower?.contactMethod?.options}
                  size="23%"
                  placeHolder={
                    templatePersonalInformation?.formInputs?.Coborrower?.contactMethod?.placeHolder || personalInformation.formInputs.Coborrower.contactMethod.placeHolder
                  }
                  labelContainer={true}
                  propertyType={PropertyType.ContactMethodCoBorrower}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.contactMethod?.isHidden ?? false}
                  isRequired={true}
                  hasAsterisk={true}
                />
                <DropDownInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.citizenship?.label || personalInformation.formInputs.Coborrower.citizenship.label}
                  options={templatePersonalInformation?.formInputs?.Coborrower?.citizenship?.options}
                  size="28%"
                  placeHolder={templatePersonalInformation?.formInputs?.Coborrower?.citizenship?.placeHolder || personalInformation.formInputs.Coborrower.citizenship.placeHolder}
                  labelContainer={true}
                  typeOfIcon="location"
                  hasAsterisk={true}
                  propertyType={PropertyType.CitizenshipCoborrower}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.citizenship?.isHidden ?? false}
                />
                <TextInput
                  label={templatePersonalInformation?.formInputs?.Coborrower?.SSN?.label || personalInformation.formInputs.Coborrower.SSN.label}
                  placeholder={templatePersonalInformation?.formInputs?.Coborrower?.SSN?.placeHolder || personalInformation.formInputs.Coborrower.SSN.placeHolder}
                  size="30%"
                  typeOfIcon="text"
                  hasAsterisk={true}
                  propertyType={PropertyType.SSNCoBorrower}
                  isInfoModal={false}
                  isHidden={templatePersonalInformation?.formInputs?.Coborrower?.SSN?.isHidden ?? false}
                  type="password"
                />
                {coBorrower !== 0 && (
                  <CheckConsent
                    text={templateConfig?.pages?.LetsGetStarted?.eConsent?.text || pages?.LetsGetStarted?.eConsent?.text}
                    infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.infoEconsent || pages?.LetsGetStarted?.modals?.infoEconsent}
                    isInfoModal={templateConfig?.pages?.LetsGetStarted?.modals?.infoEconsent?.isInfoModal || false}
                    direction={'center'}
                    isUnderline={false}
                    onChange={handleChange}
                    type={'coapplicantConsent'}
                    isHidden={templateConfig?.pages?.LetsGetStarted?.eConsent?.isHidden ?? false}
                  />
                )}
              </FormGroupComponent>
            ))}
        </Wrapper>
        <Wrapper $minHeight="0" $gap="1rem" $padding="1.25rem 0.938rem" $isMobile={isMobile}>
          {isMobile && (
            <TextBlock
              text={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consent?.text || pages.LetsGetStarted.typeOfInformation.consent.text}
              direction={'flex-start'}
              color="default"
              type={TypeOfText.H2}
            />
          )}
          <CheckConsent
            text={templatePersonalInformation?.checkConsent?.text || personalInformation.checkConsent.text}
            isInfoModal={false}
            direction={'center'}
            isUnderline={false}
            onChange={handleChange}
            type={'TCPA'}
            isHidden={templatePersonalInformation?.checkConsent?.isHidden || false}
          />
        </Wrapper>
      </PersonalInfoWrapper>
      <NavigationButton
        variant="primary"
        nextButton={templatePersonalInformation?.NavigationButton?.nextButton || personalInformation.NavigationButton.nextButton}
        isBackButton={templatePersonalInformation?.NavigationButton?.backButton?.isHidden || false}
        backButton={templatePersonalInformation?.NavigationButton?.backButton?.text || personalInformation.NavigationButton.backButton.text}
        disabled={!formError}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={previousPage}
        currentPage={PageOrigin.PERSONAL_INFORMATION}
        destinationPage={PageOrigin.EMPLOYMENT_INFORMATION}
      />
    </>
  );
};

export default PersonalInformation;
