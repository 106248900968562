import React, { useEffect, useState } from 'react';
import { TextBlock } from '@components/text/TextBlock';
import { Col, ContainerIcon, ActiveIcon, CheckedIcon, InfoIcon, ContainerCheckConsent, ContainerInfoIcon } from './CheckConsent.styles';
import { DefaultModal } from '../../Modals/DefaultModal/DefaultModal';
import { TypeOfText } from '@components/text/TextBlock';
import { ConfirmationModal } from '@Modals/ConfirmModal/ConfirmModal';
import { useAppSelector } from '@/app/hooks';

export interface RadioButtonProps {
  value?: string;
  width?: string;
  text?: string;
  isInfoModal?: boolean;
  infoModal?: {
    type?: string;
    header?: string;
    body?: string;
    button?: string;
    acceptButton?: string;
    link?: string;
  };
  checked?: boolean;
  direction?: string;
  checkIconColor?: string;
  checkIconBackground?: string;
  infoIconColor?: string;
  isUnderline?: boolean;
  onChange?: (checked: boolean, type: string) => void;
  type?: string;
  isHidden: boolean;
  isPointer?: boolean;
}

export const CheckConsent: React.FC<RadioButtonProps> = ({
  width = '100%',
  text = '',
  isInfoModal = false,
  infoModal = {},
  checked = false,
  direction = 'flex-start',
  checkIconColor = '#ffffff',
  checkIconBackground = '#5140E9',
  infoIconColor = '',
  isUnderline = false,
  type,
  isHidden,
  onChange,
  isPointer = false,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const [openModal, setOpenModal] = useState(false);
  let isConfirmModal = infoModal?.type === 'confirm';
  const currentForm = useAppSelector((state) => state.CurrentForm);
  const handleClick = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    if (onChange && type) {
      onChange(newChecked, type);
    }
  };

  const handleModal = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (type === 'eConsent' || type === 'softPullConsent' || type === 'TCPA') {
      setIsChecked(currentForm[type] || false);
    }
  }, [type]);

  return (
    <Col width={width} $direction={direction} $coapplicantConsent={type === 'coapplicantConsent'}>
      <ContainerCheckConsent $direction={direction} onClick={handleClick} className="icon" $isHidden={isHidden}>
        <ContainerIcon>
          {isChecked ? (
            <CheckedIcon data-testid="checked-icon" $checkIconColor={checkIconColor} $checkIconBackground={checkIconBackground} />
          ) : (
            <ActiveIcon data-testid="active-icon" />
          )}
        </ContainerIcon>
        <TextBlock text={text} direction={'flex-start'} color="default" type={TypeOfText.H3} width={width} infoModal={infoModal} isUnderline={isUnderline} isPointer={isPointer} />
      </ContainerCheckConsent>
      {isInfoModal && (
        <ContainerInfoIcon $isHidden={isHidden}>
          <InfoIcon data-testid="info-icon" onClick={handleModal} $infoIconColor={infoIconColor} />
        </ContainerInfoIcon>
      )}
      {openModal && isConfirmModal ? (
        <ConfirmationModal openModal={openModal} setOpenModal={setOpenModal} infoModal={infoModal} />
      ) : (
        <DefaultModal openModal={openModal} setOpenModal={setOpenModal} infoModal={infoModal} />
      )}
    </Col>
  );
};
