import React, { useEffect, useState, useRef, ChangeEvent, useCallback } from 'react';
import {
  IconContainer,
  ErrorMessage,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  DownIcon,
  Input,
  Container,
  Label,
  InputGroup,
  PhoneIcon,
} from './PhoneInput.styles';
import { pages } from '../../../defaultVerbiages';
import { useAppSelector } from '@/app/hooks';

type LoanOption = {
  label: string;
  beValue: string;
};

export interface PhoneInputProps {
  label: string;
  placeHolder: string;
  options?: LoanOption[];
  hasError?: string;
  onChange?: (option: string, propertyType?: string) => void;
  propertyType?: string | undefined;
  typeOfIcon?: string;
  isOpen?: boolean;
  isIconUp?: boolean;
  hasIcon?: boolean;
  isHidden?: boolean;
  focused?: boolean;
  isAddressIcon?: boolean;
  labelContainer?: boolean;
  type?: string;
  iconColor?: string;
  size?: string;
  hasAsterisk?: boolean;
  propertyName?: string;
  value?: string;
  isRequired?: boolean;
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  onChange,
  propertyType,
  label = '',
  placeHolder = '',
  options = [],
  typeOfIcon,
  isHidden = false,
  labelContainer = false,
  iconColor = '#5140E9',
  size = '20%',
  hasAsterisk = false,
  propertyName = '',
  value = '',
  isRequired = false,
}) => {
  const [formattedValue, setFormattedValue] = useState(value || '');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [focused, setFocused] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [isInputDisabled, setInputDisabled] = useState(true);
  const [maxLength, setMaxLength] = useState<number | undefined>(undefined);
  const hasIcon = typeOfIcon !== undefined;
  const ref = useRef<HTMLDivElement>(null);
  const labelDropDown = pages.LetsGetStarted.consumerInformation.phone.titleDropDown || 'Select';
  const isPersonalInformation = propertyName === 'personal-information';
  const currentForm = useAppSelector((state) => state.CurrentForm);
  const toggling = () => {
    setIsOpen(!isOpen);
  };
  const onOptionClicked = useCallback(
    (option: string) => () => {
      const filterOption = option.split('Phone')[0];
      setSelectedOption(filterOption);
      setIsOpen(false);
      setInputDisabled(false);
      if (onChange) {
        setInputError(false);
      }
    },
    [onChange, propertyType],
  );
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      if (!selectedOption) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setFocused(false);
    isRequired && e.target.value === '' ? setInputError(true) : setInputError(false);
  };

  const formatPropertySelected = (value: string) => {
    return value.trim().toLowerCase();
  };

  const formatInput = (input: string): string => {
    const phoneNumber = /^(\d{0,3})(\d{0,3})(\d{0,4})$/;
    const match = input.match(phoneNumber);
    if (match) {
      const [, dig, num1, num2] = match;
      let formattedDate = dig;
      if (num1) {
        formattedDate += num1;
      }
      if (num2) {
        formattedDate += num2;
        formattedDate = `(${dig}) ${num1}-${num2}`;
      }
      return formattedDate;
    }
    return input;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMaxLength(14);
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    const formatted = formatInput(inputValue);
    setFormattedValue(formatted);
    if (onChange) onChange(event.target.value, selectedOption ? formatPropertySelected(selectedOption) : propertyType);
  };

  useEffect(() => {
    if (propertyType && currentForm[propertyType] !== undefined) {
      setFormattedValue(currentForm[propertyType] || '');
    }
  }, [propertyType, value]);

  return (
    <Container ref={ref} $isHidden={isHidden} size={size} $hasAsterisk={hasAsterisk}>
      {labelContainer && <Label $hasAsterisk={hasAsterisk}>{label}</Label>}
      <InputGroup>
        <IconContainer $iconColor={iconColor} $isPersonalInformation={isPersonalInformation}>
          <PhoneIcon />
        </IconContainer>
        {!isPersonalInformation && (
          <>
            <DropDownHeader $hasIcon={hasIcon} $hasError={!!inputError} onClick={toggling}>
              <div>{selectedOption || labelDropDown}</div>
              <DownIcon $isIconUp={isOpen} />
            </DropDownHeader>
            {isOpen && (
              <DropDownListContainer>
                <DropDownList $hasError={!!inputError}>
                  {options?.map((option) => (
                    <ListItem onClick={onOptionClicked(option.beValue)} key={Math.random()}>
                      {option.beValue}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </>
        )}
        <Input
          type={'phone'}
          placeholder={placeHolder}
          value={formattedValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={isPersonalInformation ? false : isInputDisabled}
          $hasError={!!inputError}
          $focused={focused}
          $hasIcon={hasIcon}
          maxLength={maxLength}
          $isPersonalInformation={isPersonalInformation}
        />
      </InputGroup>
      {inputError && <ErrorMessage>{'you must provide a valid phone number'}</ErrorMessage>}
    </Container>
  );
};
