import React, { useEffect, useState } from 'react';
import {
  BackDrop,
  BodyText,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  WrapperInput,
  ModalBody,
  WrapperButtonRestartCode,
  Message,
  Spinner,
  WrapperButtons,
} from './ModalVerificationCode.styles';
import { Button } from '@/components/Button/DefaultButton/Button';
import { TextInput } from '@/components/input/TextInput/TextInput';
import * as yup from 'yup';
import { useAppSelector } from '@/app/hooks';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { makePostCall } from '@/services/makePostCall';
import { pages } from '../../../defaultVerbiages';

interface ModalVerificationCodeProps {
  open: boolean;
  setIsOpenEnterCode: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
  isSelected: 'start' | 'existing' | 'loanList' | undefined;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  sendCodeToEmail: () => Promise<void>;
}

const code = {
  isHidden: false,
  text: 'Verification Code',
  buttonRestart: {
    isHidden: false,
    text: `Didn't receive a code? Resend it`,
  },
};
const ModalVerificationCode = ({ open, setIsOpenEnterCode, email, setStep, sendCodeToEmail }: ModalVerificationCodeProps) => {
  const [value, setValue] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState<number>(0);

  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  let auxLoanApplication = _.cloneDeep(LoanApplication);
  let loanApplication = useAppSelector((state) => state.LoanApplication);

  const dispatch = useDispatch();

  const closeModal = () => {
    if (setIsOpenEnterCode) setIsOpenEnterCode(false);
  };

  const RESUME_CODE_REGEX = /^[a-zA-Z0-9]{6}$/;

  const yupParams = yup
    .string()
    .transform((value) => value.replace(/-/g, ''))
    .matches(RESUME_CODE_REGEX, { excludeEmptyString: false });
  const formSchema = yup.object().shape({
    'resume-code-input': yupParams,
  });

  const checkValidation = async (inputData: Record<string, string>) => {
    const varIsValid = await formSchema.isValid(inputData);
    setIsValid(varIsValid);
  };

  const verifyCode = async () => {
    try {
      setIsLoading(true);
      const endpoint = '/auth/verifyCode';
      const payload = { email, code: value };
      const newAction = {
        actionProperties: {
          endpoint,
        },
      };

      const response = await makePostCall(newAction, loanApplication, payload, dispatch, templateConfig);

      if (response) {
        const responseData = response.data;
        const borrowerProperty = responseData.borrowerProperty;
        const borrowers = responseData.borrowers[0];

        const updatedBorrower = {
          ...auxLoanApplication.borrowers[0],
          borrowerLoanPurpose: borrowers.borrowerLoanPurpose,
          borrowerFirstName: borrowers.borrowerFirstName,
          borrowerMiddleName: borrowers.middleName || '',
          borrowerLastName: borrowers.borrowerLastName,
          borrowerEmailAddress: borrowers.borrowerEmailAddress,
          borrowerPreferredContactMethod: borrowers.borrowerPreferredContactMethod || '',
          borrowerPrimaryPhone: borrowers.borrowerPrimaryPhone || '',
        };

        const updatedProperty = {
          ...auxLoanApplication.borrowerProperty,
          propertyCity: borrowerProperty.propertyCity,
          propertyStreetAddress: borrowerProperty.propertyCombinedStreetAddress,
          propertyZip: borrowerProperty.propertyZip,
          propertyState: borrowerProperty.propertyState,
        };
        auxLoanApplication.loanId = responseData.loanId;
        auxLoanApplication.loanNumber = responseData.loanNumber;
        auxLoanApplication.propertyPrimaryUse = responseData.propertyPrimaryUse;
        auxLoanApplication.propertyType = responseData.propertyType;
        auxLoanApplication.borrowers[0] = updatedBorrower;
        auxLoanApplication.borrowerProperty = updatedProperty;
        auxLoanApplication.isEligible = true;
        auxLoanApplication.isResume = true;

        dispatch({
          type: 'CurrentForm/addField',
          payload: {
            firstName: borrowers.borrowerFirstName,
            middleName: borrowers.middleName || '',
            lastName: borrowers.borrowerFirstName,
            suffix: borrowerProperty.borrowerSuffixName,
            email: borrowers.borrowerEmailAddress,
            purposeOfLoan: borrowers.borrowerLoanPurpose,
            address: borrowerProperty.propertyCombinedStreetAddress,
            propertyType: responseData.propertyType,
            primaryUse: responseData.propertyPrimaryUse,
            city: borrowerProperty.propertyCity,
            state: borrowerProperty.propertyState,
            zip: borrowerProperty.propertyZip,
            phone: borrowers.borrowerPrimaryPhone,
          },
        });

        dispatch({ type: 'LoanApplication/updateLoanApplication', payload: auxLoanApplication });
        setValidated(true);
        setIsLoading(false);
        setStep(2);
        closeModal();
      }
    } catch (error) {
      setValidated(false);
      setIsLoading(false);
    } finally {
    }
  };

  const handleResendCode = async () => {
    if (timer === 0) {
      await sendCodeToEmail();
      setTimer(60);
    }
  };

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (timer > 0) {
      countdown = setTimeout(() => setTimer((prev) => prev - 1), 1000);
    }
    return () => clearTimeout(countdown);
  }, [timer]);

  return (
    <>
      <BackDrop $isOpen={open} data-testid="modal-backdrop" />
      <Modal $isOpen={open}>
        {isLoading ? (
          <ModalBody>
            <Spinner />
            <ModalTitle $isLoading={isLoading}>
              {templateConfig?.pages?.LetsGetStarted?.modals?.loadingModal?.textOne || pages?.LetsGetStarted?.modals?.loadingModal?.textOne}
            </ModalTitle>
            <ModalContent $isLoading={isLoading}>
              {templateConfig?.pages?.LetsGetStarted?.modals?.loadingModal?.textTwo || pages?.LetsGetStarted?.modals?.loadingModal?.textTwo}
            </ModalContent>
          </ModalBody>
        ) : (
          <>
            <ModalBody>
              <ModalTitle>{'Enter Verification Code'}</ModalTitle>
              <ModalContent>
                <BodyText>{`We’ve sent a verification code to ${email}. Please enter it below.`}</BodyText>
              </ModalContent>
              <WrapperInput>
                <TextInput
                  propertyType={'code-input'}
                  placeholder={code.text}
                  isCentered
                  size={'50%'}
                  onChange={(values) => {
                    setValue(values.toString().replace(/-/g, ''));
                    checkValidation({ 'resume-code-input': values.toString() });
                  }}
                  isHidden={code.isHidden ?? false}
                />
              </WrapperInput>
              <Message $validated={validated}>
                {validated
                  ? templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.expiredCode || 'This code expires after one-time use.'
                  : templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.incorrectCode || 'Incorrect code. Please check and try again.'}
              </Message>
            </ModalBody>
            <ModalFooter>
              <WrapperButtons>
                <Button label={'Back'} variant="secondary" buttonSize="m" onClick={closeModal} />
                <Button label={'Continue'} variant="primary" buttonSize="m" isDisabled={!isValid} onClick={verifyCode} />
              </WrapperButtons>
              <WrapperButtonRestartCode>
                <Button
                  label={timer > 0 ? `Resend in ${timer}s` : code?.buttonRestart?.text}
                  onClick={handleResendCode}
                  variant="primary"
                  buttonSize="l"
                  isDisabled={timer > 0}
                  isHidden={code.buttonRestart.isHidden ?? false}
                />
              </WrapperButtonRestartCode>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};

export default ModalVerificationCode;
