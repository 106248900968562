import React, { useState, useEffect } from 'react';
import { Wrapper } from '../wrapper.styles';
import { FormGroupComponent } from '@components/input/FormGroup/FormGroup';
import { TextInput } from '@components/input/TextInput/TextInput';
import { RadioButton } from '@components/input/RadioButton/RadioButton';
import { TextBlock, TypeOfText } from '@components/text/TextBlock';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { WrapperQuestions, WrapperRadioButtons, WrapperAlimony } from './Declarations.styles';
import { useNavigate } from 'react-router-dom';

interface DeclarationsProps {
  onAccess: (page: string) => void;
}

const Declarations: React.FC<DeclarationsProps> = ({ onAccess }) => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const declarationsConfig = useAppSelector((state) => state.TemplateConfig.pages?.declarations);
  const loanApplication = useAppSelector((state) => state.LoanApplication);
  const dispatch = useAppDispatch();

  const handleRadioChange = (questionId: string, value: boolean | string | number) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [questionId]: value,
    }));

    dispatch({
      type: 'LoanApplication/updateSpecificField',
      payload: {
        depth: `borrowers[0].declaration.${questionId}`,
        value,
      },
    });
  };

  useEffect(() => {
    if (loanApplication?.borrowers?.[0]?.declaration) {
      setFormValues({
        ...loanApplication.borrowers[0].declaration,
        alimony_recipientName: loanApplication.borrowers[0].declaration.recipientName || '',
        alimony_recipientAddress: loanApplication.borrowers[0].declaration.recipientAddress || '',
        alimony_amount: loanApplication.borrowers[0].declaration.alimony || '',
        child_support_amount: loanApplication.borrowers[0].declaration.childSupport || '',
        separate_maintenance_amount: loanApplication.borrowers[0].declaration.separateMaintenanceExpense || '',
      });
    }
  }, [loanApplication]);

  const renderQuestions = (questions?: typeof declarationsConfig.form1003DeclarationQuestions) => {
    if (!questions || questions.length === 0) return null;

    return questions.map((question) => {
      if (!question || !question.verbiage || !question.id) return null;

      return (
        <FormGroupComponent key={question.id}>
          <TextBlock text={question.verbiage} type={TypeOfText.H3} />
          <WrapperRadioButtons>
            <RadioButton value="Yes" beValue={true} selectedOption={formValues[question.id] ?? ''} setSelectedOption={() => handleRadioChange(question.id, true)} border="none" />
            <RadioButton value="No" beValue={false} selectedOption={formValues[question.id] ?? ''} setSelectedOption={() => handleRadioChange(question.id, false)} border="none" />
          </WrapperRadioButtons>
          {question.id === 'hasAlimony' && formValues[question.id] === true && (
            <WrapperQuestions>
              <TextInput label="Recipient Name" placeholder="Recipient name" type="text" onChange={(value) => handleRadioChange('recipientName', value)} />
              <TextInput label="Recipient Address" placeholder="Recipient address" type="text" onChange={(value) => handleRadioChange('recipientAddress', value)} />
              <WrapperAlimony>
                <TextInput label="Alimony" placeholder="0.00" type="number" onChange={(value) => handleRadioChange('alimony', value)} />
                <TextInput label="Child Support" placeholder="0.00" type="number" onChange={(value) => handleRadioChange('childSupport', value)} />
                <TextInput label="Separate Maintenance" placeholder="0.00" type="number" onChange={(value) => handleRadioChange('separateMaintenanceExpense', value)} />
              </WrapperAlimony>
            </WrapperQuestions>
          )}
        </FormGroupComponent>
      );
    });
  };

  if (!declarationsConfig) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Wrapper>
        <TextBlock text="Form 1003 Declaration Questions" type={TypeOfText.H2} />
        {renderQuestions(declarationsConfig.form1003DeclarationQuestions)}
      </Wrapper>
      <Wrapper>
        <TextBlock text="General Declaration Questions" type={TypeOfText.H2} />
        {renderQuestions(declarationsConfig.generalDeclarationQuestions)}
      </Wrapper>
      <NavigationButton
        variant="primary"
        nextButton={declarationsConfig?.NavigationButton?.nextButton || 'Next'}
        isBackButton={declarationsConfig?.NavigationButton?.backButton?.isHidden || false}
        backButton={declarationsConfig?.NavigationButton?.backButton?.text || 'Back'}
        disabled={false}
        type="next"
        navigation={navigate}
        onAccess={onAccess}
        pageOrigin={PageOrigin.GOVERNMENT_MONITORING_INFORMATION}
        currentPage={PageOrigin.DECLARATIONS}
        destinationPage={PageOrigin.INQUIRY_SUMMARY}
      />
    </>
  );
};

export default Declarations;
